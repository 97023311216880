@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/lightmode';

.edit-recommendation {
  $breakpoint: 700px;

  &__review-header {
    margin: spacing.$l 0;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: spacing.$l;
    margin-bottom: spacing.$xl;
  }

  &__fieldset {
    border: 0;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: spacing.$m;
  }

  &__fieldset-inner {
    display: flex;
    justify-content: space-between;

    @media (max-width: $breakpoint) {
      flex-direction: column-reverse;
    }
  }

  &__input-wrapper {
    display: flex;
    gap: spacing.$l;
  }

  &__age {
    width: 100px;
  }

  &__remove {
    justify-content: flex-end;
  }

  &__list-wrapper {
    position: relative;
    z-index: 2;
    margin-right: spacing.$xs;
  }

  &__order-wrapper {
    @media (max-width: $breakpoint) {
      align-self: flex-end;
    }
  }

  &__order {
    display: flex;
    gap: spacing.$s;
    align-items: center;
  }

  &__remove-button {
    align-self: flex-end;
  }

  &__no-reviews {
    display: flex;
    align-items: center;
    gap: spacing.$s;
  }

  &__modal-content {
    > * + * {
      margin-top: spacing.$m;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-right: spacing.$s;
  }
}
